export const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });

  const url = location.href.replace(location.hash, "");
  history.pushState({}, "", `${url}#${elementId}`);
};
