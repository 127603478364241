import * as Sentry from '@sentry/browser';

const isTestEnv = process.env.NODE_ENV === 'dev';

const getEnvironmentName = (lang) => {
  const env = isTestEnv ? 'test' : 'prod';

  return `talent.rocks.landings (${env},${lang})`;
};

class SendFormError extends Error {
  constructor(...args) {
    super(...args);
    this.name = `SendFormError: ${getEnvironmentName(args[1])}`;
  }
}

const reportError = (error, breadcrumbs = []) => {
  Sentry.captureException(error, (scope) => {
    scope.setTag('create_alert', !isTestEnv);

    scope.setFingerprint([`${Date.now()}-${Math.ceil(Math.random() * 10000)}`]);

    /*
    for (const item of breadcrumbs) {
      scope.addBreadcrumb({
        level: 'log',
        message: item.name,
        data: item.data,
      });
    }
    */

    return scope;
  });
};

export const init = (lang) => {
  Sentry.init({
    dsn: 'https://e679065a71102fef57a439086cf3092e@o351613.ingest.sentry.io/4506201917095936',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: getEnvironmentName(lang),
  });
};

export const reportFormSubmissionError = (breadcrumbs = [], lang) => {
  reportError(new SendFormError('Ошибка при отправке формы', lang), breadcrumbs);
};
