import { getCookie } from './utils';
import { reportFormSubmissionError } from '../common/Sentry';
import { handleDefaultModal } from './utils';

const baseUrl =
  process.env.NODE_ENV === 'prod' ? 'https://admin.eventicious.ru' : 'https://admin-test.eventicious.ru';

const formsSelectors = '#demonstration-form, #demonstration-form-modal';
const demonstrationFormsLit = document.querySelectorAll(formsSelectors);

demonstrationFormsLit.forEach((demonstrationForm) => initDemonstrationForm(demonstrationForm));

function initDemonstrationForm(demonstrationForm) {
  const nameField = demonstrationForm.querySelector('[name=name]');
  const emailField = demonstrationForm.querySelector('[name=email]');
  const phoneField = demonstrationForm.querySelector('[name=phone]');
  const messageField = demonstrationForm.querySelector('[name=message]');
  const modalHeaderInput = demonstrationForm.querySelector('input[name=modal-header]');
  const submitButton = demonstrationForm.querySelector('button[type=submit]');

  demonstrationForm.addEventListener('submit', onSubmit);

  async function onSubmit(e) {
    e.preventDefault();
    nameField.disabled = true;
    emailField.disabled = true;
    phoneField.disabled = true;
    messageField.disabled = true;
    submitButton.disabled = true;
    submitButton.classList.add('button--loading');

    const modalTarget = modalHeaderInput ? ` [${modalHeaderInput.value}]` : '';

    const payload = {
      name: nameField.value,
      email: emailField.value,
      phone: phoneField.value,
      LandingType: 'Talentrocks',
      source: 'Лендинг Talent Rocks, KZ-RU' + modalTarget,
      confirm: 'true',
      roistatVisitId: getCookie('roistat_visit'),
      time: getDate(),
      gcLid: getCookie('gcLid'),
      message: `${messageField.value}\u200B\u200B\u200B`,
    };

    try {
      const answer = await fetch(`${baseUrl}/api/subscribe/request_demo_new`, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (answer.status === 200) {
        demonstrationForm.classList.add('success');
        demonstrationForm.reset();
      } else {
        throw new Error(answer);
      }
    } catch (error) {
      reportFormSubmissionError(
        [
          {
            name: 'response',
            data: error,
          },
          {
            name: 'payload',
            data: payload,
          },
        ],
        'kz-ru',
      );

      handleDefaultModal('#error-modal');
    } finally {
      nameField.parentElement.classList.remove('error');
      emailField.parentElement.classList.remove('error');
      phoneField.parentElement.classList.remove('error');
      nameField.disabled = false;
      emailField.disabled = false;
      phoneField.disabled = false;
      messageField.disabled = false;
      submitButton.disabled = false;
      submitButton.classList.remove('button--loading');
    }
  }
}

function getDate() {
  const d = new Date();

  const to2Digit = function (number) {
    return number < 10 ? '0' + number : number;
  };

  return `${d.getFullYear()}-${to2Digit(d.getMonth() + 1)}-${to2Digit(d.getDate())}, ${to2Digit(
    d.getHours(),
  )}:${to2Digit(d.getMinutes())}:${to2Digit(d.getSeconds())}`;
}
